import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { Sticky } from 'semantic-ui-react'

import Header from './header'
import Nav from './nav'
import Footer from './footer'

import 'semantic-ui-less/semantic.less'

class RawLayout extends Component {
  constructor(props) {
    super(props)
    this.contextRef = createRef()
  }

  render() {
    return (
      <div ref={this.contextRef}>
        <Header siteTitle={this.props.data.site.siteMetadata.title} />
        <Sticky context={this.contextRef} offset={0}>
          <Nav contextRef={this.contextRef} />
        </Sticky>

        <div id="main">{this.props.children}</div>

        <Footer contextRef={this.contextRef} />
      </div>
    )
  }
}

const Layout = (props) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' },
          ]}
          htmlAttributes={{
            lang: 'de',
          }}
        />

        <RawLayout data={data} {...props} />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
