import React from 'react'
import { Grid, Menu, Container } from 'semantic-ui-react'

import {
  MyFooter,
  BlackLink,
  GreyBackground,
  CompactRow,
} from './layout-components'

const Footer = () => (
  <GreyBackground>
    <Container text>
      <Grid container padded>
        <CompactRow centered devided columns={1}>
          <Grid.Column textAlign="center">
            <Menu secondary text compact size="small">
              <Menu.Item>
                <BlackLink to="/imprint">Impressum</BlackLink>
              </Menu.Item>
              <Menu.Item>|</Menu.Item>
              <Menu.Item>
                <BlackLink to="/privacy">
                  Datenschutz&shy;erkl&auml;rung
                </BlackLink>
              </Menu.Item>
            </Menu>
          </Grid.Column>
        </CompactRow>
        <CompactRow centered>
          <MyFooter>&copy; Copyright 2020 - Ronald Steinke</MyFooter>
        </CompactRow>
      </Grid>
    </Container>
  </GreyBackground>
)

export default Footer
