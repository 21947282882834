import React from 'react'
import { Link } from 'gatsby'
import {
  Menu,
  Segment,
  Container,
  Header,
  Grid,
  Rating,
} from 'semantic-ui-react'
import styled from 'styled-components'

import OverlayImg from '../css/images/overlay.png'
import HighlightImg from '../css/images/highlight.png'

export const LinkedItem = ({ children, ...props }) => (
  <Menu.Item as={WhiteLink} activeClassName="active" {...props}>
    {children}
  </Menu.Item>
)

export const MyHeader = styled.h1`
  text-align: right !important;
  padding: 1rem 1rem !important;
  margin-bottom: 0rem !important;
`

export const MyFooter = styled.h5`
  padding: 0.2rem 0.1rem !important;
  margin-top: 0rem !important;
`

export const JustifyParagraph = styled.p`
  text-align: justify !important;
  hyphens: auto;
`

export const RightParagraph = styled.p`
  text-align: right !important;
`

export const WhiteLink = styled(Link)`
  color: white !important;
`

export const BlackLink = styled(Link)`
  color: black !important;
`

export const CompactRow = styled(Grid.Row)`
  padding-top: 0 !important;
  padding-bottom: 0 !important;
`

export const NoMarginSegment = styled(Segment)`
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
`

export const GreyBackground = styled.div`
  background: grey !important;
  background-image: url(${OverlayImg}) !important;
`

const ColoredSegment = styled(Segment)`
  margin: 0 !important;
  background-color: ${(props) => props['bg-color']} !important;
  background-image: url(${OverlayImg}), url(${HighlightImg}) !important;
  background-position: top left, center center !important;
  background-size: auto, 100% 250% !important;
  background-repeat: repeat, no-repeat !important;
`

export const primaryLayoutColor = '#f3e2c5'

export const secondaryLayoutColor = '#e3d2b5'

export const LayoutSection = ({ children, color, header, text }) => (
  <ColoredSegment basic padded bg-color={color}>
    <Container text={text}>
      {header && <Header size="large" content={header} />}
      {children}
    </Container>
  </ColoredSegment>
)

LayoutSection.defaultProps = {
  color: primaryLayoutColor,
}

export const RatingRow = ({ item, rating, maxRating }) => (
  <Grid.Row>
    <Grid.Column width={8} textAlign="right">
      {item}
    </Grid.Column>
    <Grid.Column width={8}>
      <Rating rating={rating} maxRating={maxRating} disabled />
    </Grid.Column>
  </Grid.Row>
)

RatingRow.defaultProps = {
  maxRating: 7,
}
