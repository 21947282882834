import React from 'react'
import { Container, Menu } from 'semantic-ui-react'

import {
  GreyBackground,
  LinkedItem,
} from './layout-components'

const Nav = () => (
  <GreyBackground>
    <Container text>
      <Menu secondary fluid>
        <Menu.Menu position="right">
          <LinkedItem to="/">Home</LinkedItem>
          <LinkedItem to="/about-me">&Uuml;ber mich</LinkedItem>
          <LinkedItem to="/projects">Projekte</LinkedItem>
          <LinkedItem to="/skills">Erfahrung</LinkedItem>
        </Menu.Menu>
      </Menu>
    </Container>
  </GreyBackground>
)

export default Nav
