import React from 'react'
import { Container } from 'semantic-ui-react'

import {
  MyHeader,
  WhiteLink,
  GreyBackground,
} from './layout-components'

const Header = ({ siteTitle }) => (
  <GreyBackground>
    <Container text>
      <MyHeader>
        <WhiteLink to="/">{siteTitle}</WhiteLink>
      </MyHeader>
    </Container>
  </GreyBackground>
)

export default Header
